<template>
  <div
    class="d-flex flex-column justify-start align-center flex-md-row justify-md-start align-md-center my-2"
  >
    <v-avatar :color="`${colorTheme} lighten-4`" size="80">
      <v-icon dark :color="colorTheme"> mdi-{{ icon }} </v-icon>
    </v-avatar>

    <!-- <div
      class="d-flex flex-column justify-md-center align-md-start pt-2 pt-md-3 pl-md-4"
    > -->
    <v-row class="mt-3 mt-md-n2 ml-md-4 justify-center align-center">
      <v-col cols="12" md="8" class="ma-0 pa-0">
        <p class="font-weight-bold text-h5 mb-1 text-center text-md-left">
          {{ title }}
        </p>
        <p
          class="font-weight-medium text--secondary text-body-1 text-center text-md-left mb-0 "
        >
          {{ subTitle }}
        </p>
      </v-col>
      <v-col cols="12" md="4" class="ma-0 pa-0">
        <v-btn
          dense
          small
          rounded
          outlined
          block
          :loading="loading"
          :color="`${colorTheme}`"
          @click="excecuteAction()"
          class=" mt-3 mt-md-0 white--text"
        >
          {{ buttonLegend }}
          <v-icon right dark>
            mdi-arrow-right-circle
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "IconCardWithActionButton",
  props: {
    colorTheme: {
      type: String,
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false
    },
    buttonLegend: {
      type: String,
      default: "Ver detalle"
    }
  },
  methods: {
    excecuteAction() {
      this.$emit("action");
    },
  },
};
</script>
