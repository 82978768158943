<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="375"
  >
    <v-form>
      <v-card>
        <v-card-title class="text-h6 font-weight-medium">
          <div class="d-flex flex-row">
            <v-icon medium color="red" left>mdi-alert</v-icon>
            <slot name="title"></slot>
          </div>
        </v-card-title>
        <v-card-text class="text-body-1 black--text text-justify">
          Atención: si elimina
          <slot name="subject"></slot>, el registro se eliminará permanentemente
          y NO se puede deshacer.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="cancel">Cancelar</v-btn>
          <v-btn
            color="red"
            class="white--text"
            depressed
            :loading="isLoading"
            @click="confirm"
            >Sí, eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteBaseModals",
  data() {
    return {
      isLoading: false,
      dialog: false,
    };
  },
  methods: {
    confirm() {
      this.isLoading = true;
      this.$emit("delete");
      this.toggleModal();
      this.isLoading = false;
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    cancel() {
      this.fireToast({
        icon: "info",
        title: "El registro selecionado no ha sido eliminado.",
      });
      this.toggleModal();
    },
  },
};
</script>
