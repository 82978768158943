import { PedagogicalDiaryService } from "@/core/services/apis.service";

const RESOURCE = "code-places";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllCodePlaces = () => {
  return PedagogicalDiaryService.get(`${RESOURCE}`);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getOneCodePlaces = (id) => {
  return PedagogicalDiaryService.get(`${RESOURCE}/${id}`);
};

export const createCodePlace = (payload) => {
  return PedagogicalDiaryService.post(`${RESOURCE}`, payload);
};

export const updateCodePlace = (id, payload) => {
  return PedagogicalDiaryService.put("code-places", id, payload);
};

export const deleteCodePlace = (id) => {
  return PedagogicalDiaryService.delete(`${RESOURCE}/${id}`);
};

export default {
  getAllCodePlaces,
  getOneCodePlaces,
  createCodePlace,
  updateCodePlace,
  deleteCodePlace,
};
