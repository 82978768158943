<template>
  <div class="ma-0 pa-0">
    <v-sheet :class="`${disabledStatus} rounded d-flex`" elevation="2">
      <v-hover v-slot:default="{ hover }">
        <v-row>
          <v-col cols="12" sm="3"
            ><div
              :class="
                `${
                  codeTypeSchema().tabColor
                } d-flex align-center fixed-height header-bg-img py-sm-4 rounded`
              "
            >
              <div class="container d-flex justify-center">
                <p
                  :class="
                    `text-${
                      codeTypeSchema().foregroundColor
                    } text-h6 text-center font-weight-medium mb-0 tabTitle`
                  "
                >
                  {{ code.CodeType.name }}
                </p>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="9"
            class="pl-sm-0 pr-sm-6 pt-0 pt-sm-2 d-flex flex-column justify-center"
          >
            <div class="mt-sm-3">
              <p class="mb-0 text-overline text-center">
                {{ correlative + 1 }} / {{ total }}
              </p>
              <p
                class="
               text-body-1 text-center font-weight-medium mb-3 mb-sm-0 px-3 px-sm-3
            "
              >
                " {{ code.name }} "
              </p>
            </div>
            <div class="mt-2 d-flex justify-center align-center mt-auto">
              <v-scroll-y-reverse-transition
                :hide-on-leave="true"
                :leave-absolute="true"
              >
                <div
                  transition="dialog-bottom-transition"
                  v-if="hover"
                  class="mt-2 mb-3 mx-1 align-self-end"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="blue-grey darken-3"
                        fab
                        x-small
                        dark
                        @click="
                          selectCode(code);
                          showMdlUpdate();
                        "
                      >
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <span>Ajustes</span>
                  </v-tooltip>
                </div>
              </v-scroll-y-reverse-transition>
            </div>
          </v-col>
        </v-row>
      </v-hover>
    </v-sheet>
    <!-- this component contains the update modal (UpdateCodeModal); and the
    update modal contains the delete option. -->
    <UpdateCodeModal
      ref="mdlUpdate"
      :code="selectedCode"
      :codeTypes="codeTypes"
    ></UpdateCodeModal>
  </div>
</template>

<script>
import UpdateCodeModal from "@/components/modals/codes/UpdateCodeModal.vue";

export default {
  name: "CodeCard",
  props: {
    code: {
      type: Object,
    },
    codeTypes: {
      type: Array,
    },
    total: {
      type: Number,
    },
    correlative: {
      type: Number,
    },
  },
  components: {
    UpdateCodeModal,
  },
  data() {
    return {
      selectedCode: {},
    };
  },
  methods: {
    selectCode(obj) {
      // non-reactive copy of data for the update modal
      this.selectedCode = {
        id: obj.id,
        name: obj.name,
        description: obj.description,
        code_type_id: obj.code_type_id,
        is_active: obj.is_active,
      };
    },
    showMdlUpdate() {
      this.$refs.mdlUpdate.toggleModal();
    },
    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
    codeTypeSchema() {
      /*if the code type pattern needs to be changed
       individually, a new propierty can be added to 
       each case in the switch statement*/
      switch (this.code.CodeType.name) {
        case "Positivo":
          return {
            tabColor: "blue darken-1",
            foregroundColor: "white",
            tabIcon: "star",
          };
        case "Leve":
          return {
            tabColor: "amber darken-1",
            foregroundColor: "white",
            tabIcon: "star-minus",
          };
        case "Grave":
          return {
            tabColor: "deep-orange accent-3",
            foregroundColor: "white",
            tabIcon: "star-minus",
          };
        case "Muy grave":
          return {
            tabColor: "red accent-4",
            foregroundColor: "white",
            tabIcon: "star-minus",
          };
        default:
          return {
            tabColor: "grey accent-4",
            foregroundColor: "white",
            tabIcon: "star-minus",
          };
      }
    },
  },
  computed: {
    disabledStatus() {
      return this.code.is_active ? "" : "disabled-code";
    },
  },
};
</script>

<style>
.fixed-height {
  height: 100%;
  min-height: 180px;
}

.disabled-code {
  opacity: 0.6;
}

.header-bg-img {
  background-color: #ffffff00;
  background-image: url("https://www.transparenttextures.com/patterns/maze-white.png");
  background-repeat: repeat;
}

.tabTitle {
  writing-mode: vertical-rl;
  display: inline;
  color: white;
  transform: rotate(-0.5turn);
}

@media (max-width: 600px) {
  .tabTitle {
    transform: rotate(-0.25turn);
  }
  .fixed-height {
    height: 60px !important;
    min-height: 60px;
  }
}
</style>
