<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Gestión de códigos
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar, crear, actualizar y eliminar todos
              tipos de código.</span
            >
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button New Record-->
          <v-btn
            elevation="0"
            color="primary"
            @click="showMdlCreate"
            v-if="existInArray('Agregar', currentPageActions)"
          >
            <span class="svg-icon svg-icon-md svg-icon-white mr-1">
              <inline-svg src="media/svg/icons/Home/Book-open.svg"></inline-svg>
            </span>
            Crear código
          </v-btn>
          <!--end::Button New Record-->
        </div>
      </div>

      <div class="card-body">
        <v-row class="mb-3">
          <v-col cols="12">
            <v-card outlined class="pa-4 pr-8 elevation-0 rounded-lg">
              <IconCardWithActionButton
                title="Tipos de código"
                subTitle="Añadir, modificar o eliminar tipos de código"
                icon="account-cog-outline"
                colorTheme="light-blue"
                @action="showMdlCodeTypes()"
              ></IconCardWithActionButton>
            </v-card>
          </v-col>
        </v-row>
        <!--begin::filters and search-->
        <v-container class="pa-4">
          <!-- if the search box is being used, the select for code types is disabled and viceversa. -->
          <v-row class="align-items-center mb-5">
            <!-- begin::search box-->
            <v-col cols="12" sm="7" class="my-2 my-md-0">
              <div class="d-flex align-items-center">
                <!-- begin:: academicGroup combo -->
                <v-text-field
                  rounded
                  filled
                  hide-details
                  prepend-icon=""
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Buscar código"
                  v-model="filters.search"
                  :disabled="canUseSearch"
                ></v-text-field>
                <!-- end:: academicGroup combo -->
              </div>
            </v-col>
            <!-- end::search box-->

            <!-- begin::select section/group filter-->
            <v-col cols="12" sm="5" class="my-2 my-md-0">
              <div class="d-flex align-items-center">
                <!-- begin:: academicGroup combo -->
                <v-select
                  :label="
                    codeTypes.isLoading
                      ? 'Cargando tipos...'
                      : 'Filtrar por tipo de código'
                  "
                  :items="codeTypes.data"
                  :loading="codeTypes.isLoading"
                  :disabled="codeTypes.isLoading || !canFilterByType"
                  item-text="name"
                  item-value="id"
                  v-model="filters.code_type_id"
                  hide-details
                  rounded
                  filled
                  clearable
                >
                  <!-- begin::selected academicGroup item -->
                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      class="mb-2 mt-3"
                      color="primary"
                      small
                      v-if="index === 0"
                    >
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                  <!-- end::selected academicGroup item -->
                </v-select>
                <!-- end:: academicGroup combo -->
              </div>
            </v-col>
            <!-- end::select section/group filter-->
          </v-row>
        </v-container>
        <!-- end::filters and search-->

        <!-- begin::codes container -->
        <v-row
          class="d-flex justify-center align-center"
          v-if="codes.data.length < 1"
        >
          <!-- fallback for when there's not any code registered -->
          <v-col cols="12">
            <v-row class="d-flex flex-column">
              <v-col cols="4" sm="2" class="mx-auto mb-2 mt-5">
                <img
                  style="width: 100%"
                  src="../../../../public/media/bg/empty.png"
                  alt=""
                />
              </v-col>
              <p class="text-h6 font-weight-normal text-center mb-0">
                No hay ningún código registrado.
              </p>
              <p class="text-center mx-4">
                Para añadir uno nuevo, haga click en el botón
                <span class="font-weight-bold">"Crear código"</span>.
              </p>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else class="d-flex justify-center align-center">
          <!-- fallback for when there's not any code that matches the filters-->
          <v-col cols="12" v-if="filteredCodes < 1">
            <v-row class="d-flex flex-column">
              <v-col cols="4" sm="2" class="mx-auto mb-2 mt-5">
                <img
                  style="width: 100%"
                  src="../../../../public/media/bg/searchResults.png"
                  alt=""
                />
              </v-col>
              <p
                v-if="!canUseSearch"
                class="text-body-1 font-weight-normal text-center mb-0"
              >
                No se encontró ningún registro que contenga
                <span class="font-weight-bold">"{{ this.filters.search }}"</span
                >.
              </p>
              <p
                v-if="canFilterByType"
                class="text-body-1 font-weight-normal text-center mb-0"
              >
                No se encontró ningún código del tipo seleccionado.
              </p>
            </v-row>
          </v-col>

          <v-row v-else class="px-4 mb-4">
            <v-col cols="12" md="6" lg="7">
              <p class="text-h6 font-weight-bold text-center mt-5">Códigos</p>
              <v-card class="elevation-0 pa-3 mt-2 rounded-lg" outlined>
                <!-- this component contains the update modal (UpdateCodeModal); and the
            update modal contains the delete option. -->
                <v-list-item-group v-model="selectedItem" color="primary">
                  <v-list class="v-list">
                    <v-list-item
                      @click="
                        selectCode(item);
                        showMdlUpdate();
                      "
                      :class="`${disabledStatus(item)}`"
                      v-for="(item, index) in filteredCodes"
                      :key="index"
                    >
                      <v-list-item-content class="pb-0">
                        <v-list-item-subtitle class="mb-1">
                          <v-chip
                            :color="`${
                              setCodeTypeTheme(item.CodeType?.name).color
                            } lighten-4 font-weight-medium`"
                            class="mt-1"
                          >
                            <p
                              :class="`font-weight-medium ${
                                setCodeTypeTheme(item.CodeType?.name).color
                              }--text mb-0`"
                            >
                              Código
                              <span
                                :class="`text-lowercase font-weight-bold ${
                                  setCodeTypeTheme(item.CodeType?.name).color
                                }--text`"
                                >{{ item.CodeType?.name }}</span
                              >
                            </p>
                          </v-chip>

                          <v-chip
                            v-if="!item.is_active"
                            :color="`${
                              setCodeTypeTheme(item.CodeType?.name).color
                            } lighten-4 font-weight-medium`"
                            class="mt-1 ml-2"
                          >
                            <p
                              :class="`font-weight-bold ${
                                setCodeTypeTheme(item.CodeType?.name).color
                              }--text mb-0`"
                            >
                              DESHABILITADO
                            </p>
                          </v-chip>
                        </v-list-item-subtitle>

                        <p class="font-weight-bold text-body-1 mb-0 ml-3">
                          {{ item.name }}
                        </p>

                        <p class="font-weight-medium text--secondary ml-3">
                          {{ item.description }}
                        </p>

                        <v-divider class="mb-0"></v-divider>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-list-item-group>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" lg="5">
              <v-card class="elevation-0 pa-3 mt-2">
                <p class="text-h6 font-weight-bold text-center">
                  Recuento de códigos creados
                </p>
                <v-row>
                  <v-col cols="12" class="mb-n10" v-if="!!positiveCodesAmount">
                    <IconCard
                      :title="`${positiveCodesAmount} ${
                        positiveCodesAmount > 1
                          ? 'códigos positivos'
                          : 'código positivo'
                      }`"
                      :subTitle="`de ${totalCodesAmount} en total.`"
                      icon="account-card-outline"
                      colorTheme="green"
                    ></IconCard>
                  </v-col>
                  <v-col cols="12" class="mb-n10" v-if="!!leveCodesAmount">
                    <IconCard
                      :title="`${leveCodesAmount} ${
                        leveCodesAmount > 1 ? 'códigos leves' : 'código leve'
                      }`"
                      :subTitle="`de ${totalCodesAmount} en total.`"
                      icon="account-card-outline"
                      colorTheme="orange"
                    ></IconCard>
                  </v-col>
                  <v-col cols="12" class="mb-n10" v-if="!!graveCodesAmount">
                    <IconCard
                      :title="`${graveCodesAmount} ${
                        graveCodesAmount > 1 ? 'códigos graves' : 'código grave'
                      }`"
                      :subTitle="`de ${totalCodesAmount} en total.`"
                      icon="account-card-outline"
                      colorTheme="red"
                    ></IconCard>
                  </v-col>
                  <v-col cols="12" class="mb-n10" v-if="!!muyGraveCodesAmount">
                    <IconCard
                      :title="`${muyGraveCodesAmount} ${
                        muyGraveCodesAmount > 1
                          ? 'códigos muy graves'
                          : 'código muy grave'
                      }`"
                      :subTitle="`de ${totalCodesAmount} en total.`"
                      icon="account-card-outline"
                      colorTheme="purple"
                    ></IconCard>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-row>

        <!-- end::codes container -->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::modals -->
    <CreateCodeModal
      ref="mdlCreate"
      @refresh="loadCodes()"
      :codeTypes="codeTypes.data"
    ></CreateCodeModal>
    <UpdateCodeModal
      ref="mdlUpdate"
      :code="selectedCode"
      :codeTypes="codeTypes.data"
      @refresh="loadCodes"
    ></UpdateCodeModal>
    <ViewCodeTypesModal
      ref="mdlViewCodeTypes"
      colorTheme="light-blue"
      :codeTypes="codeTypes.data"
      :loadingState="codeTypes.isLoading"
      @refresh="loadCodeTypes"
    ></ViewCodeTypesModal>
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import codeRepository from "@/repositories/codeRepository";
import codeTypeRepository from "@/repositories/codeTypeRepository";
import CreateCodeModal from "@/components/modals/codes/CreateCodeModal.vue";
import IconCardWithActionButton from "@/components/elements/dashboard/IconCardWithAction.vue";
import IconCard from "@/components/elements/dashboard/IconCard.vue";
import SquaredIconCard from "@/components/elements/dashboard/SquaredIconCard.vue";
import CodeCard from "@/components/elements/code/CodeCard.vue";
import UpdateCodeModal from "@/components/modals/codes/UpdateCodeModal.vue";
import ViewCodeTypesModal from "@/components/modals/code_types/ViewCodeTypes.vue";

export default {
  name: "Codes",
  title: "Códigos | GE ITR",

  data() {
    return {
      selectedItem: null,
      expanded: [],
      singleExpand: false,
      selectedCode: {},
      filters: {
        search: "",
        code_type_id: null,
      },
      codeTypes: {
        data: [],
        isLoading: false,
      },
      codePlaces: {
        data: [],
        isLoading: false,
      },
      codes: {
        data: [],
        isLoading: false,
        filteredCodeList: null,
      },
    };
  },

  components: {
    IconCardWithActionButton,
    SquaredIconCard,
    ViewCodeTypesModal,
    CreateCodeModal,
    UpdateCodeModal,
    CodeCard,
    IconCard,
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Códigos", route: "codes" },
    ]);
    this.loadCodeTypes();
    this.loadCodes();
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Codigos");
  },

  methods: {
    // selectCode(obj) {
    //   // non-reactive copy of data for the update modal
    //   this.selectedCode = {
    //     id: obj.id,
    //     name: obj.name,
    //     description: obj.description,
    //     code_type_id: obj.code_type_id,
    //     is_active: obj.is_active,
    //   };
    // },

    showMdlCodeTypes() {
      this.$refs.mdlViewCodeTypes.toggleCodeTypeModal();
      this.loadCodeTypes();
    },
    loadCodes() {
      codeRepository
        .getAllCodes()
        .then(({ data }) => {
          this.codes.data = data.data;
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: `No ha sido posible cargar los datos desde el servidor`,
          });
        })
        .finally(() => {
          this.selectedItem = null;
        });
    },
    loadCodeTypes() {
      this.codeTypes.isLoading = true;
      codeTypeRepository
        .getAllCodeTypes()
        .then(({ data }) => {
          this.codeTypes.data = data.data;
        })
        .catch((error) => {
          // this.fireToast({
          //   icon: "error",
          //   title: `No ha sido posible cargar los datos desde el servidor`,
          // });
        })
        .finally(() => {
          this.codeTypes.isLoading = false;
        });
    },
    selectCode(obj) {
      this.selectedCode = { ...obj };
    },
    showMdlCreate() {
      this.$refs.mdlCreate.toggleModal();
    },
    showMdlUpdate() {
      this.$refs.mdlUpdate.toggleModal();
    },

    disabledStatus(code) {
      return code.is_active ? "" : "disabled-code";
    },

    setCodeTypeTheme(codeTypeName) {
      let codeTypeSchemas = {
        Positivo: {
          color: "green",
          icon: "star",
        },
        Leve: {
          color: "orange",
          icon: "star-minus",
        },
        Grave: {
          color: "red",
          icon: "star-minus",
        },
        "Muy grave": {
          color: "purple",
          icon: "star-minus",
        },
      };

      let defaultSchema = {
        color: "grey",
        icon: "star-minus",
      };

      if (codeTypeSchemas.hasOwnProperty(codeTypeName)) {
        return codeTypeSchemas[codeTypeName];
      } else {
        return defaultSchema;
      }
    },
  },

  computed: {
    ...mapGetters(["currentPageActions"]),

    canFilterByType() {
      return !!this.filters.search == "";
    },
    canUseSearch() {
      return !!this.filters.code_type_id;
    },

    positiveCodesAmount() {
      let codes = this.codes.data.filter((code) => {
        return code.CodeType.name === "Positivo";
      });
      return codes.length;
    },
    leveCodesAmount() {
      let codes = this.codes.data.filter((code) => {
        return code.CodeType.name === "Leve";
      });
      return codes.length;
    },
    graveCodesAmount() {
      let codes = this.codes.data.filter((code) => {
        return code.CodeType.name === "Grave";
      });
      return codes.length;
    },
    muyGraveCodesAmount() {
      let codes = this.codes.data.filter((code) => {
        return code.CodeType.name === "Muy grave";
      });
      return codes.length;
    },

    totalCodesAmount() {
      return this.codes.data.length;
    },

    // Filters by search or type selection
    filteredCodes() {
      if (!!this.filters.code_type_id) {
        return this.codes.data.filter(
          (code) => code.code_type_id == this.filters.code_type_id
        );
      } else {
        return this.codes.data.filter((code) =>
          code.name.toLowerCase().includes(this.filters.search.toLowerCase())
        );
      }
    },
  },

  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>

<style scoped>
.disabled-code {
  opacity: 0.65;
  filter: grayscale(1);
}

.v-list {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  max-height: 360px;
  overflow-y: auto;
}
</style>
