<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="550"
    persistent
  >
    <v-form>
      <v-card>
        <!-- begin::Modal title -->
        <v-toolbar dark color="grey darken-4" class="elevation-0 px-3">
          <span class="headline">Modificar código</span>
        </v-toolbar>
        <!-- end::Modal title -->

        <!-- begin::modal content and fields -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="estado" :validator="$v.code.is_active">
                  <p class="mb-0 text-h6 font-weight-light">
                    Estado de código:
                  </p>
                  <v-switch
                    v-model="code.is_active"
                    :label="`${isDisabled ? 'Activo' : 'Inactivo'}`"
                    color="success"
                  ></v-switch>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="nombre" :validator="$v.code.name">
                  <v-textarea
                    rows="2"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Código"
                    placeholder="Ej.: Come en clase..."
                    required
                    rounded
                    filled
                    v-model="code.name"
                  ></v-textarea>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="descripción" :validator="$v.code.description">
                  <v-textarea
                    rows="3"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Descripción"
                    placeholder="Ej.: Este código se aplica cuando..."
                    required
                    rounded
                    filled
                    v-model="code.description"
                  ></v-textarea>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group
                  name="tipo de código"
                  :validator="$v.code.code_type_id"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Tipo de código"
                    :items="codeTypes"
                    item-value="id"
                    item-text="name"
                    v-model="code.code_type_id"
                    hide-details
                    rounded
                    filled
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip
                        color="primary"
                        class="mt-3 ml-n1"
                        small
                        v-if="index === 0"
                      >
                        <span>{{ item.name }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <!-- end::modal content and fields -->

        <!-- begin::actions -->
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn color="amber" depressed :loading="isLoading" @click="submit"
            >Actualizar</v-btn
          >
          <!-- the delete action has been moved into the update modal; 
            given that the option it's not that important in order to 
            show it in the main part of the view, but it's not hard
            to reach either way. -->
          <v-btn
            color="red darken-1"
            depressed
            :loading="isLoading"
            @click="
              selectCode(code);
              showMdlDelete();
            "
            >Eliminar
          </v-btn>
        </v-card-actions>
        <!-- end::actions -->
      </v-card>
    </v-form>
    <DeleteBaseModal ref="mdlDelete" @delete="deleteCode()">
      <template v-slot:title>¿Desea eliminar este código?</template>
      <template v-slot:subject>
        el código
        <span class="font-weight-bolder">{{ code.name }}</span>
      </template>
    </DeleteBaseModal>
  </v-dialog>
</template>

<script>
import {
  required,
  maxLength,
  minLength,
  helpers,
} from "vuelidate/lib/validators";
import codeRepository from "@/repositories/codeRepository";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";

const alpha = helpers.regex("alpha", /^[a-zA-Z\-_.,;ñÑáéíóúÁÉÍÓÚ\s]+$/i);

export default {
  name: "UpdateCodeTypeModal",

  data() {
    return {
      isLoading: false,
      dialog: false,
      selectedCode: null,
    };
  },

  components: {
    DeleteBaseModal,
  },

  props: {
    code: {
      type: Object,
    },
    codeTypes: {
      type: Array,
    },
  },

  methods: {
    update() {
      this.isLoading = true;
      codeRepository
        .updateCode(this.code.id, this.code)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data and hidding modal if operation has been complete
          // this event is executed at the index, the one that contains the CodeCard Component.
          this.closeModal();
        })
        .catch((error) => {
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            this.fireToast({
              icon: "error",
              title: "No ha sido posible actualizar el registro",
            });
            return;
          }
          if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            this.fireToast({
              icon: "error",
              title: "Ocurrió un error al comunicarse con el servidor",
            });
            return;
          }
          // Something happened in setting up the request and triggered an Error
          this.fireToast({
            icon: "error",
            title: "Ha ocurrido un error inesperado",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.update();
    },

    toggleModal() {
      this.dialog = !this.dialog;
      // this.$emit("refresh");
    },

    closeModal() {
      this.$emit("refresh");
      this.toggleModal();
      this.$v.$reset();
    },

    deleteCode() {
      codeRepository
        .deleteCode(this.selectedCode.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          this.closeModal();
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: error.response.data.message,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    selectCode(obj) {
      // non-reactive data for the delete modal
      this.selectedCode = { ...obj };
    },

    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
  },
  computed: {
    isDisabled() {
      if (
        typeof this.code.is_active === "undefined" ||
        this.code.is_active === null
      ) {
        return this.code.is_active;
      }
      return false;
    },
  },
  validations: {
    code: {
      name: {
        required,
        maxLength: maxLength(100),
        minLength: minLength(5),
        alpha,
      },
      description: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(10),
        alpha,
      },
      code_type_id: {
        required,
      },
    },
  },
};
</script>
