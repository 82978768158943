import { PedagogicalDiaryService } from "@/core/services/apis.service";

const RESOURCE = "code-types";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllCodeTypes = () => {
  return PedagogicalDiaryService.get(`${RESOURCE}`);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getOneCodeTypes = (id) => {
  return PedagogicalDiaryService.get(`${RESOURCE}/${id}`);
};

export const createCodeType = (payload) => {
  return PedagogicalDiaryService.post(`${RESOURCE}`, payload);
};

export const updateCodeType = (id, payload) => {
  return PedagogicalDiaryService.put(`${RESOURCE}`,id, payload);
};

export const deleteCodeType = (id) => {
  return PedagogicalDiaryService.delete(`${RESOURCE}/${id}`);
};

export default {
  getAllCodeTypes,
  getOneCodeTypes,
  createCodeType,
  updateCodeType,
  deleteCodeType,
};
