<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="550"
    persistent
  >
    <v-form>
      <v-card>
        <!-- begin::Modal title -->
        <v-toolbar dark color="primary" class="elevation-0 px-3">
          <span class="headline">Agregar código</span>
        </v-toolbar>
        <!-- end::Modal title -->

        <!-- begin::modal content and fields -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="estado" :validator="$v.code.is_active">
                  <p class="mb-0 text-h6 font-weight-light">
                    Estado de código:
                  </p>
                  <v-switch
                    v-model="code.is_active"
                    :label="`${code.is_active ? 'Activo' : 'Inactivo'}`"
                    color="success"
                  ></v-switch>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="nombre" :validator="$v.code.name">
                  <v-textarea
                    rows="1"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Código"
                    placeholder="Ej.: Come en clase..."
                    required
                    filled
                    rounded
                    v-model="code.name"
                  ></v-textarea>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="descripción" :validator="$v.code.description">
                  <v-textarea
                    rows="2"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Descripción"
                    placeholder="Ej.: Este código se aplica cuando..."
                    required
                    filled
                    rounded
                    v-model="code.description"
                  ></v-textarea>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group
                  name="tipo de código"
                  :validator="$v.code.code_type_id"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Tipo de código"
                    :items="codeTypes"
                    item-value="id"
                    item-text="name"
                    v-model="code.code_type_id"
                    hide-details
                    filled
                    rounded
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip color="primary" small v-if="index === 0">
                        <span>{{ item.name }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <!-- end:: modal content and fields -->

        <!-- begin::actions -->
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn color="primary" depressed :loading="isLoading" @click="submit"
            >Agregar</v-btn
          >
        </v-card-actions>
        <!-- end::actions -->
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  required,
  maxLength,
  minLength,
  helpers,
} from "vuelidate/lib/validators";
import codeRepository from "@/repositories/codeRepository";

const alpha = helpers.regex("alpha", /^[a-zA-Z\-_.,;ñÑáéíóúÁÉÍÓÚ\s]+$/i);

export default {
  name: "CreateCodeTypeModal",

  data() {
    return {
      isLoading: false,
      dialog: false,
      code: {
        name: null,
        description: null,
        code_type_id: null,
        is_active: true,
      },
    };
  },

  props: {
    codeTypes: {
      type: Array,
    },
  },

  methods: {
    create() {
      this.isLoading = true;
      codeRepository
        .createCode(this.code)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data and hidding modal if operation has been completed
          this.$emit("refresh");
          this.closeModal();
        })
        .catch((error) => {
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            this.fireToast({
              icon: "error",
              title: "No ha sido posible crear el registro",
            });
            return;
          }
          if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            this.fireToast({
              icon: "error",
              title: "Ocurrió un error al comunicarse con el servidor",
            });
            return;
          }
          // Something happened in setting up the request and triggered an Error
          this.fireToast({
            icon: "error",
            title: "Ha ocurrido un error inesperado",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.create();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.resetCode();
    },

    resetCode() {
      this.code = {
        name: null,
        description: null,
        code_type_id: null,
        is_active: true,
      };
    },
  },
  validations: {
    code: {
      name: {
        required,
        maxLength: maxLength(100),
        minLength: minLength(5),
        alpha,
      },
      description: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(10),
        alpha,
      },
      code_type_id: {
        required,
      },
    },
  },
};
</script>
