<template>
  <v-card
    :outlined="outlined"
    class="d-flex flex-column justify-center align-center pa-4 elevation-0 rounded-lg"
  >
    <v-avatar :color="`${colorTheme} lighten-4`" size="75">
      <v-icon dark :color="colorTheme"> mdi-{{ icon }} </v-icon>
    </v-avatar>

    <div class="d-flex flex-column pt-2">
      <p class="font-weight-bold text-h5 mb-0 text-center">
        {{ title }}
      </p>
      <p class="font-weight-medium text-body-1 text-center">
        {{ subTitle }}
      </p>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "SquaredIconCard",
  props: {
    colorTheme: {
      type: String,
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    outlined: {
      type: Boolean,
      default: true
    }
  },
};
</script>
