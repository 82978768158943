import { PedagogicalDiaryService } from "@/core/services/apis.service";

const RESOURCE = "codes";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllCodes = () => {
  return PedagogicalDiaryService.get(`${RESOURCE}`);
};
/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllEnabledCodes = () => {
  return PedagogicalDiaryService.get(`${RESOURCE}/enabled`);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getOneCodes = (id) => {
  return PedagogicalDiaryService.get(`${RESOURCE}/${id}`);
};

export const createCode = (payload) => {
  return PedagogicalDiaryService.post(`${RESOURCE}`, payload);
};

export const updateCode = (id, payload) => {
  return PedagogicalDiaryService.put(`${RESOURCE}`, id, payload);
};

export const deleteCode = (id) => {
  return PedagogicalDiaryService.delete(`${RESOURCE}/${id}`);
};

// -----------------code assigning--------------------

export const assignCodeToStudent = (payload) => {
  return PedagogicalDiaryService.post(`code-details/assign-code`, payload)
};

export const updateDetailsCode = (id, payload) => {
  return PedagogicalDiaryService.put(`code-details`, id, payload);
}

export const deleteAssignedCode = (id) => {
  return PedagogicalDiaryService.delete(`assign-code/${id}`);
}

export default {
  getAllCodes,
  getAllEnabledCodes,
  getOneCodes,
  createCode,
  updateCode,
  deleteCode,
  // -------
  assignCodeToStudent,
  updateDetailsCode,
  deleteAssignedCode,
};
