<template>
  <v-dialog
    v-model="dialogActiveState"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark :color="`${colorTheme} lighten-1`">
        <v-btn icon dark @click="dialogActiveState = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialogActiveState = false">
            Cerrar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-container>
        <v-row class="my-3">
          <v-col cols="12">
            <v-card outlined class="pa-4 pr-8 elevation-0 rounded-lg">
              <IconCardWithActionButton
                title="Ubicaciones de código"
                subTitle="Añadir, modificar o eliminar ubicaciones de código"
                icon="account-cog-outline"
                colorTheme="blue"
                @action="showMdlCodePlaces()"
              ></IconCardWithActionButton>
            </v-card>
          </v-col>
        </v-row>

        <!-- begin::Card -->
        <div class="card card-custom gutter-b">
          <div class="card-header flex-nowrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                Gestión de tipos de código
                <span class="d-block text-muted pt-2 font-size-sm"
                  >Aqui se podra visualizar, crear, actualizar y eliminar todos
                  tipos de código.</span
                >
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Button New Record-->
              <v-btn
                elevation="0"
                color="primary"
                @click="showMdlCreate"
                v-if="existInArray('Agregar', currentPageActions)"
              >
                <span class="svg-icon svg-icon-md svg-icon-white mr-1">
                  <inline-svg
                    src="media/svg/icons/Home/Book-open.svg"
                  ></inline-svg>
                </span>
                Crear tipo de código
              </v-btn>
              <!--end::Button New Record-->
            </div>
          </div>
          <div class="card-body">
            <!-- begin::Search Form -->
            <div class="mb-5">
              <div class="row align-items-center">
                <div class="col-lg-9 col-xl-8">
                  <div class="row align-items-center">
                    <div class="col-md-5 my-2 my-md-0">
                      <v-text-field
                        rounded
                        filled
                        hide-details
                        prepend-icon=""
                        prepend-inner-icon="mdi-magnify"
                        placeholder="Buscar"
                        v-model="codeTypesTable.search"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end::Search Form -->
            <!--begin: Datatable-->
            <v-card class="rounded-lg elevation-1">
              <v-data-table
                :headers="codeTypesTable.headers"
                :items="codeTypes"
                :search="codeTypesTable.search"
                :items-per-page="5"
                :loading="loadingState"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                show-expand
              >
                <!-- begin: Actions -->
                <template v-slot:[`item.name`]="{ item }">
                  <template>
                    <p class="text-body-1 font-weight-bold mb-0">
                      {{ item.name }}
                    </p>
                  </template>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <template v-if="existInArray('Editar', currentPageActions)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="showMdlUpdate(), selectCodeType(item)"
                          color="amber"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </template>
                  <template v-if="existInArray('Eliminar', currentPageActions)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-5"
                          @click="showMdlDelete(), selectCodeType(item)"
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </template>
                </template>
                <!-- begin: Expand -->
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-container>
                      <v-row>
                        <!-- description card -->
                        <v-col cols="12" sm="6">
                          <div
                            class="pa-6 my-3 rounded-lg dashboard-card mx-auto grey lighten-4"
                          >
                            <p class="text-h4 font-weight-bold mb-0 opacity-90">
                              {{ item.max_amount_per_period }} códigos
                            </p>
                            <p class="text-overline">
                              Es el número máximo de asignaciones con este tipo.
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </td>
                </template>
                <!-- end: Expand -->
              </v-data-table>
            </v-card>
            <!-- end: Datatable-->
          </div>
        </div>
        <!-- end::Card -->

        <!-- begin::modals -->
        <CreateCodeTypeModal ref="mdlCreate" @refresh="refreshList()">
        </CreateCodeTypeModal>
        <UpdateCodeTypeModal
          ref="mdlUpdate"
          :codeType="selectedCodeType"
          @refresh="refreshList()"
        ></UpdateCodeTypeModal>
        <DeleteBaseModal ref="mdlDelete" @delete="deleteCodeType()">
          <template v-slot:title>¿Desea eliminar este tipo de código?</template>
          <template v-slot:subject>
            el tipo de código
            <span class="font-weight-bolder">{{ selectedCodeType.name }}</span>
          </template>
        </DeleteBaseModal>
        <ViewCodePlacesModal
          ref="mdlViewCodePlaces"
          colorTheme="blue"
        ></ViewCodePlacesModal>
        <!-- end::modals -->
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import IconCardWithActionButton from "@/components/elements/dashboard/IconCardWithAction.vue";
import codeTypeRepository from "@/repositories/codeTypeRepository";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";
import CreateCodeTypeModal from "@/components/modals/code_types/CreateCodeTypeModal.vue";
import UpdateCodeTypeModal from "@/components/modals/code_types/UpdateCodeTypeModal.vue";
import ViewCodePlacesModal from "@/components/modals/code_places/ViewCodePlaces.vue";

export default {
  name: "ViewCodeTypesModal",
  props: {
    codeTypes: {
      type: Array,
    },
    loadingState:{
      type: Boolean,
    },
    colorTheme: {
      type: String,
    },
  },

  data() {
    return {
      dialogActiveState: false,
      expanded: [],
      singleExpand: false,
      selectedCodeType: {},
      codeTypesTable: {
        headers: [
          { text: "Tipo de código", value: "name" },
          { text: "Acciones", value: "actions", sortable: false },
          {
            text: "Detalles",
            value: "data-table-expand",
            sortable: false,
            align: "center",
          },
        ],

        search: "",
      },
    };
  },
  components: {
    CreateCodeTypeModal,
    DeleteBaseModal,
    UpdateCodeTypeModal,
    IconCardWithActionButton,
    ViewCodePlacesModal,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tipos de código", route: "codeTypes" },
    ]);

  
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Horas clase");
  },
  methods: {

    refreshList(){
      this.$emit("refresh")
    },

    deleteCodeType() {
      codeTypeRepository
        .deleteCodeType(this.selectedCodeType.id)
        .then(({ data }) => {
    // Showing alert with response data
          this.fireToast({
              icon: "success",
              title: data.message,
            });
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: error.response.data.message,
          });
        })
        .finally(() => {
          // emit refresh de los code-types
          this.$emit("refresh")
        });
    },

    selectCodeType(obj) {
      this.selectedCodeType = { ...obj };
    },
    showMdlCreate() {
      this.$refs.mdlCreate.toggleCreateModal();
    },
    showMdlUpdate() {
      this.$refs.mdlUpdate.toggleModal();
    },
    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
    toggleCodeTypeModal() {
      this.dialogActiveState = !this.dialogActiveState;
    },
    showMdlCodePlaces() {
      this.$refs.mdlViewCodePlaces.toggleModal();
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>

<style>
.card-icon-container {
  aspect-ratio: 1/1;
  height: 100%;
  max-height: 75px;
}

.text-card {
  height: 20vh;
}
</style>
