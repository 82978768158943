<template>
  <v-dialog
    v-model="dialogActiveState"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark :color="`${colorTheme} lighten-1`">
        <v-btn icon dark @click="dialogActiveState = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialogActiveState = false">
            Cerrar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-container>
        <!-- begin::Card -->
        <div class="card card-custom gutter-b">
          <div class="card-header flex-nowrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                Gestión de ubicaciones de código
                <span class="d-block text-muted pt-2 font-size-sm"
                  >Aqui se podra visualizar, crear, actualizar y eliminar todas
                  las ubicaciones de código.</span
                >
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Button New Record-->
              <v-btn
                elevation="0"
                color="primary"
                @click="showMdlCreate"
                v-if="existInArray('Agregar', currentPageActions)"
              >
                <span class="svg-icon svg-icon-md svg-icon-white mr-1">
                  <inline-svg
                    src="media/svg/icons/Home/Book-open.svg"
                  ></inline-svg>
                </span>
                Crear ubicación de código
              </v-btn>
              <!--end::Button New Record-->
            </div>
          </div>
          <div class="card-body">
            <!-- begin::Search Form -->
            <div class="mb-5">
              <div class="row align-items-center">
                <div class="col-lg-9 col-xl-8">
                  <div class="row align-items-center">
                    <div class="col-md-5 my-2 my-md-0">
                      <v-text-field
                        rounded
                        filled
                        hide-details
                        prepend-icon=""
                        prepend-inner-icon="mdi-magnify"
                        placeholder="Buscar"
                        v-model="codePlaces.search"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end::Search Form -->
            <!--begin: Datatable-->
            <v-card class="elevation-1 rounded-lg">
              <v-data-table
                :headers="codePlaces.headers"
                :items="codePlaces.data"
                :search="codePlaces.search"
                :items-per-page="5"
                :loading="codePlaces.isLoading"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                show-expand
              >
                <!-- begin: Actions -->
                <template v-slot:[`item.actions`]="{ item }">
                  <template v-if="existInArray('Editar', currentPageActions)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="showMdlUpdate(), selectCodePlace(item)"
                          color="amber"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </template>
                  <template v-if="existInArray('Eliminar', currentPageActions)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-5"
                          @click="showMdlDelete(), selectCodePlace(item)"
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </template>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <template>
                    <p class="text-body-1 font-weight-bold mb-0">
                      {{ item.name }}
                    </p>
                  </template>
                </template>
                <!-- begin: Expand -->
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-container>
                      <v-row>
                        <!-- more details about code place -->
                        <v-col cols="12">
                          <p class="text-h6 mb-2">Descripción</p>
                          <p class="text-body-1 mb-2 font-weight-medium">
                            {{ item.description }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </td>
                </template>
                <!-- end: Expand -->
              </v-data-table>
            </v-card>
            <!-- end: Datatable-->
          </div>
        </div>
        <!-- end::Card -->

        <!-- begin::modals -->
        <CreateCodePlaceModal ref="mdlCreate" @refresh="loadCodePlaces()">
        </CreateCodePlaceModal>
        <UpdateCodePlaceModal
          ref="mdlUpdate"
          :codePlace="selectedCodePlace"
          @refresh="loadCodePlaces()"
        ></UpdateCodePlaceModal>
        <DeleteBaseModal ref="mdlDelete" @delete="deleteCodePlace()">
          <template v-slot:title
            >¿Desea eliminar esta ubicación de código?</template
          >
          <template v-slot:subject>
            La ubicación de código
            <span class="font-weight-bolder">{{ selectedCodePlace.name }}</span>
          </template>
        </DeleteBaseModal>
        <!-- end::modals -->
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import codePlaceRepository from "@/repositories/codePlaceRepository";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";
import CreateCodePlaceModal from "@/components/modals/code_places/CreateCodePlaceModal.vue";
import UpdateCodePlaceModal from "@/components/modals/code_places/UpdateCodePlaceModal.vue";

export default {
  name: "ViewCodePlacesModal",
  title: "Ubicaciones de código | GE ITR",
  data() {
    return {
      dialogActiveState: false,
      expanded: [],
      singleExpand: false,
      selectedCodePlace: {},
      codePlaces: {
        headers: [
          { text: "Tipo de ubicación", value: "name" },
          { text: "Acciones", value: "actions", sortable: false },
          {
            text: "Detalles",
            value: "data-table-expand",
            sortable: false,
            align: "center",
          },
        ],
        data: [],
        search: "",
        isLoading: false,
      },
    };
  },
  components: {
    CreateCodePlaceModal,
    DeleteBaseModal,
    UpdateCodePlaceModal,
  },
  props: {
    colorTheme: {
      type: String,
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ubicaciones para código", route: "codePlaces" },
    ]);

    this.loadCodePlaces();
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Horas clase");
  },
  methods: {
    loadCodePlaces() {
      codePlaceRepository
        .getAllCodePlaces()
        .then(({ data }) => {
          this.codePlaces.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: `No es posible eliminar el registro seleccionado en estos momentos 🙁`,
          });
        });
    },
    deleteCodePlace() {
      codePlaceRepository
        .deleteCodePlace(this.selectedCodePlace.id)
        .then(({ data }) => {
          /// Showing alert with response data
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          // Retrieving data if operation has been completed
          if (data.status) {
            this.codePlaces.data = this.codePlaces.data.filter(
              (item) => item.id !== this.selectedCodePlace.id
            );
          }
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: error.response.data.message,
          });
        })
        .finally(() => {
          this.loadCodePlaces();
        });
    },
    selectCodePlace(obj) {
      this.selectedCodePlace = { ...obj };
    },
    showMdlCreate() {
      this.$refs.mdlCreate.toggleModal();
    },
    showMdlUpdate() {
      this.$refs.mdlUpdate.toggleModal();
    },
    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
    toggleModal() {
      this.dialogActiveState = !this.dialogActiveState;
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
